export const members = [

    {
      name: "Soren",
      title: "Dr.",
      first_name: "Sören",
      last_name: "Wacker",
      role: "Research HPC Engineer",
      skills: ["Analytics Engineering", "Machine Learning", "High Performance Computing"],
      image: "img/s-wacker.jpeg",
      room: "28.6.W.760",
      bio: "Sören Wacker is a Senior Research HPC Engineer in the newly formed Research Engineering and Infrastructure Team (REIT) at TU Delft, where he specializes in Analytics Engineering, Machine Learning, and High Performance Computing. He completed his PhD at the Max Planck Institute for Biophysical Chemistry (now the Max Planck Institute for Multidisciplinary Science) and the University of Göttingen in Germany. His professional journey includes significant experience at the Center for Molecular Simulations and the Ian Lewis Research Group in Canada, where he contributed to the large-scale applied research project ResistanceDB. He has also collaborated with several Canadian startups in the fields of drug development and machine learning. Currently, Sören supports the CropXR project, a 100 million Euro initiative focused on advancing crop research over the next decade. His work reflects a commitment to leveraging advanced computational methods to solve complex challenges.",
    },
    
    {
      name: "Azza",
      title: "",
      first_name: "Azza",
      last_name: "Ahmed",
      role: "Research HPC Engineer",
      skills: ["HPC", "Machine Learning", "Bioinformatics pipelines"],
      image: "img/a-ahmed.jpeg",
      room: "28.6.W.760",
      bio: "Azza majored in control and instrumentation (electrical engineering) from the University of Khartoum (Sudan), and then extrapolated that to control theory in man and machines in her MSc on Cybernetics from the University of Reading (UK). She then pursued a double-degree PhD between the University of Khartoum and Groningen where she focused on building and deploying NGS pipelines across different infrastructure across the African Bioinformatics Netowrk (H3ABioNet) and looking for causal signals in GWAS data. Prior to joining TU Delft, Azza was a senior bioinformatician at a Dutch startup where she focused on building and optimizing analysis pipelines for cancer detection.",
    },
  
    {
      name: "Jeff",
      title: "",
      first_name: "Jeff",
      last_name: "Smits",
      role: "Research Software Engineer",
      skills: ["Compiler Technology", "SAT-CP Solvers", "Software Engineering", "Rust"],
      image: "img/j-smits.jpeg",
      room: "28.3.E.420, 28.4.E.420",
      bio: "Jeff studied Computer Science at TU Delft at the Bachelor, Master, PhD, and postdoc level. He received his BSc cum laude, with a minor in Robotics. During his master's he completed an Honours Programme project exploring incremental type inference in the Programming Language research group. For his MSc thesis with the same group he visited Oracle Labs in Redwood City, CA. There he ported a compiler for the Green-Marl programming language from C++ to the Spoofax language workbench. For his thesis he also formalised the static semantics of the language. His PhD and postdoc research focussed on improvements to the Spoofax language workbench, including a new domain specific language for control- and data-flow analysis (FlowSpec), and multiple improvements to the term rewriting language Stratego. He continued his relationship with Oracle Labs with research visits to apply his research. As a Research Software Engineer Jeff still maintains and develops parts of Spoofax, but he has also been able to use this position to explore some of his many other interests in Computer Science topics, such as SAT-CP solvers. ",
    },
  
    {
      name: "Shaad",
      title: "",
      first_name: "Shaad",
      last_name: "Alaka",
      role: "Research Software Engineer",
      skills: ["Web Applications", "Game Development", "Computer Graphics"],
      image: "img/s-alaka.jpeg",
      room: "28.5.W.600, 28.3.W.920",
      bio: "Shaad studied Computer Science at the TU Delft, finishing both a BSc (Cum Laude) and an MSc (Software Engineering track), with a master's thesis on Hierarchical Semantic Wave Function Collapse under the Computer Graphics and Visualization group, which was also presented during the FDG 2023 conference PCG workshop. During his MSc, he already began operating unofficially as a research engineer, working on a broad spectrum of projects, among which: building a system for crowd-sourcing the digitalization of sheet music as part of the TROMPA EU project, creating a backend for an Apache Flink stream-processing MOOC, building a variety of demos/SPAs and front-ends for the Web Information Systems group, and contributing to the data discovery module of the Opertus Mundi EU project in the form of a backend and a Jupyterlab extension. Next to this, Shaad is also lead programmer, lead sound designer, composer and producer of Invertigo, a self-published 3D puzzler video game.",
    },
  
    {
      name: "Sreeparna",
      title: "",
      first_name: "Sreeparna",
      last_name: "Deb",
      role: "Research Software Engineer",
      skills: ["NLG/NLP", "Responsible AI", "API development"],
      image: "img/s-deb.jpeg",
      room: "28.3.W.600",
      bio: "I am a Research Engineer with a Master's degree in data science, currently focusing on Language Models and NLP at TU Delft for a personal assistant. For my Master’s thesis, I developed a value-based framework for Explainable AI evaluation. Working for three years in the financial sector, I contributed to IAM software serving 20M+ customers. My role now involves applying advanced AI and machine learning techniques to address research challenges, drawing on my experience to innovate in the field of AI-driven software development.",
    },
  
    {
      name: "Pamela",
      title: "Dr.",
      first_name: "Pamela",
      last_name: "Wochner",
      role: "Research Software Engineer",
      skills: ["Machine Learning", "Software Engineering", "Python", "Rust"],
      image: "img/p-wochner.jpeg",
      room: "28.3.W.600",
      bio: "After a MSc in electrical engineering (specializing in signal and image processing) at the Karlsruhe Institute of Technology, she moved to King's College London to do her PhD. Her research focused on novel acquisition methods for diffusion magnetic resonance imaging included theoretical work on the underlying physics, Monte-Carlo simulations, and performing MR experiments. Before joining REIT, she worked as a Research Data Scientist at The Alan Turing Institute, London. Here, she contributed to a wide range of open-source software and data science projects, on topics such as trustworthy digital identity, cloud management infrastructure and electric vehicle grid integration. ",
    },
  
    {
      name: "Era",
      title: "",
      first_name: "Era",
      last_name: "Dorta",
      role: "Research Software Engineer",
      skills: ["Computer Vision", "Software Engineering", "Python"],
      image: "img/e-dorta.jpeg",
      room: "28.6.W.760",
      bio: "Era is a versatile research software engineer specializing in machine learning and computer vision. They are enthusiastic about research with practical applications and solving real world problems. Completing a PhD at the University of Bath in the UK, Era collaborated with Anthropics Ltd. and University College London to pioneer novel methods in photo editing using deep generative statistical models. Their work resulted in publications in top-tier computer vision conferences. Transitioning to industry, Era held roles as a research engineer and later as a senior research engineer at Synthesia Ltd. In these positions, they played a significant role in enhancing the text-to-video technology, contributing the development of new features, as well as optimizing and maintaining code for production deployment. Currently, Era continues to make contributions across diverse projects. From applied camera hardware setup with the CaptureLab, to deployment of secure compute to data web environments in the Energy-SHR project.",
    },
  
    {
      name: "Roland",
      title: "",
      first_name: "Roland",
      last_name: "Kromes",
      role: "Research Software Engineer",
      skills: ["IoT", "Security and Privacy", "Blockchain"],
      image: "img/r-kromes.jpeg",
      room: "29.2.260",
      bio: "Roland obtained a PhD in electronic engineering at the Université Côte d'Azur, France. His doctoral career was very varied and resulted in the creation of numerous APIs and protocols adapted to constrained IoT devices, enabling seamless communication with various blockchains. In addition, he designed and developed a specific IoT hardware architecture model for blockchain applications to accelerate cryptography-related operations. Later, as a post-doctoral researcher at Delft University of Technology, his research focused on applied cryptography. His work led to improved energy efficiency for enhanced data privacy and security in IoT applications. On the other hand, he has developed protocols to enable secret data sharing and data protection in blockchain applications and blockchain-related use cases. Currently, Roland is working on various projects (Union’s Horizon Europe SEPTON), including the development of a secret key agreement protocol in medical data sharing, information leakage verification in cyber-physical systems, the integration of fully homomorphic encryption with deep learning diffusion models and project management.",
    },
  
    {
      name: "Ruud",
      title: "",
      first_name: "Ruud",
      last_name: "deJong",
      role: "HPC Engineer",
      skills: ["Linux Administration"],
      image: "img/r-deJong.jpeg",
      room: "28.6.W.720",
      bio: "Ruud is responsible for ordering, configuring and maintaining the hardware in the department. This concerns laptops, desktops, servers, robots etc. He coordinates the use of the labspace and is often involved in setting up equipment for experiments. He maintains the various mailinglists, virtual servers and webservers in the department and also acts as an intermediate between the department and central ICT. He is involved in a joint project with the TU Delft Climate Institute and maintains a number of weather stations in the Rotterdam Area, including the server where the data is collected and the website where the data is displayed.",
    },
  
    {
      name: "Thomas",
      title: "Dr.",
      first_name: "Thomas",
      last_name: "Abeel",
      role: "Associate Professor",
      skills: ["Python", "Machine Learning", "Data Management"],
      image: "img/t-abeel.webp",
      room: "28.6.W.840",
      bio: "Thomas Abeel is an Associate Professor at the Department of Intelligent Systems of the Faculty of Electrical Engineering, Mathematics and Computer Science at the TU Delft. He is working with the Broad Bacterial Genomics group on several projects, including methods development projects.<br><br>He obtained his M.Sc. in Computer Science and Ph.D. in Biotechnology from Ghent University in the group of Yves Van de Peer, investigating machine learning approaches for biomarker discovery and visualization techniques for high throughput sequencing data. He performed postdoctoral research in the labs of James Galagan and Ashlee Earl at the Broad Institute of MIT and Harvard focusing on understanding the emergence of drug resistance through genomic and transcriptomic analyses of various pathogenic bacteria.<br><br>Dr. Abeel leads the Computational Microbial Genomics Group at the Delft Bioinformatics Lab. His team leverages computation and visualization to investigate the molecular activities of microbial organisms. He is interested in the effects of changes in genotype and their effects on molecular and extended phenotypes, in particular phenotypes of medical or industrial importance. In the medical area we focus on antibiotic resistance in pathogenic bacteria, while in the industrial arena we focus on mutations that affect yield and conversion efficiency. The group is specialized in creating novel algorithms to accurately reconstruct the complex heterogeneous genome and transcriptome architectures found in these organisms from large sequencing experiments and to identify relevant mutations.",
    },
  
    {
      name: "Léon",
      title: "Dr.",
      first_name: "Léon",
      last_name: "Planken",
      role: "Research Software Engineer",
      skills: ["Algorithms", "Software Engineering", "Python", "Java"],
      image: "img/l-planken.jpeg",
      room: "28.4.E.060",
      bio: "Léon pursued his bachelor, master and doctoral studies at Delft University of Technology. He was awarded an Honorable Mention for ICAPS Best Dissertation for his work on new and efficient algorithms for shortest path and scheduling problems. After concluding his Ph.D., he worked for six years as a senior software engineer and project manager at a software company for a diverse set of customers, while also keeping in touch with his former TU Delft research group, co-supervising Bachelor and Master students.",
    
    },
  
    {
      name: "Mehdi",
      title: "",
      first_name: "Mehdi",
      last_name: "Keshani",
      role: "Research Software Engineer",
      skills: ["Software Engineering ", "Java", "Dependency Management"],
      image: "img/m-keshani.jpeg",
      room: "28.6.W.760",
      bio: "Mehdi is a software engineer and researcher who completed his PhD at Delft University of Technology in the Software Engineering Research Group. During his PhD, he contributed to the Fasten project, an initiative aimed at improving dependency analyses by enhancing their precision through the use of call graphs. His research focused on various aspects of software supply chains and dependency management. He addressed critical issues such as change impact analysis of libraries, security vulnerabilities within libraries, best practices for release management in software ecosystems, and reproducible libraries. Before his PhD, Mehdi completed his master's studies at Sharif University of Technology in Tehran, and prior to that, his bachelor's studies at Isfahan University in Iran."
    },
  
    {
      name: "Avishek",
      title: "Dr.",
      first_name: "Avishek",
      last_name: "Anand",
      role: "Associate Professor",
      skills: ["Explainable AI", "Information Retrieval", "Question Answering"],
      image: "img/a-anand.jpeg",
      room: "28.3.W.660",
      bio: "Avishek Anand is an Associate Professor in the Web Information Systems (WIS) at the Software Technology department at Delft University of Technology (TU Delft). His research aims to develop intelligent and transparent machine learning approaches to help humans find relevant information. Specifically, he is interested in Explainable Information Retrieval. He holds a PhD in computer science from the Max Planck Insitute for Informatics, Saarbrücken. Previously, he was an Assistant professor in Information Retrieval in Lebniz University Hannover. His research is supported by Amazon research awards, Schufa Gmbh, BMBF, and EU Horizon 2020. He is also a member of the L3S Research Center and was a visiting scholar in Amazon Search. He also leads the Research, Engineering, and Infrastructure Team (REIT) at the Department of Software Technology."
      },
  ];
  